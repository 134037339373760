import { graphql, useStaticQuery } from "gatsby";
import React, { ReactElement, FC } from "react";
import type { FooterData } from './footer';


type SocialsProps = {
    iconClassnames?: string;
};

export const SocialLinks: FC<SocialsProps> = ({iconClassnames}): ReactElement => {
    const data = useStaticQuery(graphql`
        {
            allContentfulFooter {
                nodes {
                    socialLinks {
                        icon {
                            file {
                                url
                            }
                        }
                        url
                        name
                    }
                }
            }
        }
    `);

    const footerData: FooterData = data.allContentfulFooter.nodes[0];
    return (
        <div className="flex flex-row justify-center gap-4">
            {footerData.socialLinks.map(link => {
                return (
                    <a
                        href={link.url}
                        className="flex items-center"
                        aria-label={link.name}
                        key={link.name}
                    >
                        <img
                            className={`footer__social-link align-middle self-center w-8 ${iconClassnames}`}
                            src={link.icon.file.url}
                            alt={link.name}
                        />
                    </a>
                );
            })}
        </div>
    );
};
