import React, { ReactElement, useState } from "react";
import LoginContainer from "../components/forms/loginFormContainer";
import login from "./login";
import { Button, TextInput } from "luxd";
import isEmail from "validator/lib/isEmail";
import { useForm } from "react-hook-form";
import { FormStatus } from "../components/forms/forms";
import { SocialLinks } from "../components/SocialLinks";
import { joinDiscord } from "../utils/navigation";
import { FaDiscord } from "react-icons/fa";
import { ApiCall } from "../appwrite/appwrite";
import { Token } from "../types";
import useAppwrite from "../hooks/useAppwrite";
import { appwriteRecoveryUrl } from "../constants";
import { toast } from "react-toast";
import { MdLock } from "react-icons/md";

type ForgotFormInputs = {
    email: string;
};

const Forgot = (): ReactElement => {
    const { register, handleSubmit, errors } = useForm<ForgotFormInputs>();
    const [status, setStatus] = useState<FormStatus>();

    const appwrite = useAppwrite();
    const onSubmit = (data: ForgotFormInputs) => {
        setStatus("loading");
        ApiCall<Token>(
            appwrite.account.createRecovery(data.email, appwriteRecoveryUrl),
            tokenResp => {
                setStatus("success");
                toast.success("Recovery email sent");
            },
            err => {
                setStatus("error");
                toast.error(err.message);
            }
        );
    };

    return (
        <LoginContainer>
            <div className="flex flex-col space-y-8 justify-between mx-4 mt-8 text-offWhite">
                <div className="flex flex-row items-center space-x-4 justify-center mx-8">
                    <div>
                        <MdLock size={32} />
                    </div>
                    <h1 className="text-2xl font-display text-center">
                        Password reset request
                    </h1>
                </div>
                <p className="text-lg mx-8">
                    Enter your email address below and we'll send you an email
                    with a link to recover your password.
                </p>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="mt-6 flex flex-col space-y-4"
                >
                    <TextInput
                        id="email"
                        name={"email"}
                        label={"Email address"}
                        placeholder={"Email address"}
                        type="email"
                        required
                        inputMode="email"
                        ref={register({
                            required: "This field is required",
                            validate: (value: string) =>
                                isEmail(value) || "Enter valid email address",
                        })}
                        error={errors.email?.message}
                    />

                    <Button
                        loading={status === "loading"}
                        color="primary"
                        size="normal"
                    >
                        Send recovery email
                    </Button>
                </form>
                <div className="flex flex-col space-y-4 items-center text-offWhite">
                    <div className="flex flex-row sm:space-x-4 space-x-2 justify-center pt-4 items-center">
                        <div className="flex flex-row items-center">
                            <p className="whitespace-nowrap">
                                Account trouble?
                            </p>
                        </div>
                        <Button
                            icon={<FaDiscord />}
                            size="small"
                            onClick={() => joinDiscord()}
                        >
                            Join our Discord
                        </Button>
                    </div>
                </div>
                <SocialLinks />
            </div>
        </LoginContainer>
    );
};

export default Forgot;
